<template>
  <div class="page-faq">
    <n-loader :loading="$var('load')" />
    <top-bar back :title="$app.secure.clean($n.t('17.1'))" backroute="profile" />
    <div class="container">
      <div class="wrap">
        <div v-for="item in faqs" :key="item.id" class="item" :class="{'opened': item.id === openedId}"
             @click="open(item)">
          <div class="title">{{ item.question }}</div>
          <div class="img">
            <n-icon icon="plus" />
          </div>
          <div v-if="openedId === item.id" class="content">{{ item.answer }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  data() {
    return {
      openedId: null,
      faqs: [],
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.faqs.get().then((response) => {
        this.faqs = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    open(item) {
      this.openedId = this.openedId === item.id ? null : item.id
    },
  },
}
</script>

<style scoped lang="scss">
.page-faq {
  .wrap {
    .item {
      padding: 16px 16px 26px;
      border: 1px solid #D9D9D9;
      border-radius: 12px;
      position: relative;
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }

      .img {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #FAAD14;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(0deg);
        transition: all .2s ease-in-out;
      }


      &.opened {
        .img {
          background: #1E1E1E;
          transform: rotate(45deg);
        }
      }

      .title {
        font-weight: 500;
        font-size: 20px;
        color: #102E4F;
        padding-right: 45px;
      }

      .content {
        font-weight: 400;
        font-size: 16px;
        color: #102E4F;
        padding-top: 20px;
      }
    }
  }
}
</style>
